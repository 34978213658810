import classNames from 'classnames';
import Media from 'components/Media';
import RichText from 'components/RichText';

const Hero = ({ id, codename, title, description, media, foreground, hide_secondary_graphic, jump_links }) => {
	return (
		<div
			className={classNames('hero', {
				'hero--lg': jump_links,
				'hero--hide-boxes': hide_secondary_graphic,
			})}
			data-kontent-item-id={id}
			data-kontent-element-codename={codename}
		>
			<div className='hero__container'>
				<div className='hero__boxes'>
					<div className='hero__large-box'>
						{title && <RichText className='hero__title' data={title} />}
						{description && <RichText className='hero__description' data={description} />}
					</div>
					<div className='hero__small-box' />
				</div>
				{foreground && (
					<Media
						layout={'fill'}
						objectFit={'contain'}
						wrapperClassName='hero__foreground'
						className={'hero__foreground-image'}
						objectPosition={'right center'}
						media={foreground}
						disableCaption
						disableElevation
						priority
					/>
				)}
			</div>
			<div className='hero__images'>
				<Media
					wrapperClassName={'hero__image'}
					media={media}
					layout={'fill'}
					objectFit='cover'
					objectPosition={'56% top'}
					priority
					disableCaption
					disableElevation
				/>
			</div>
		</div>
	);
};

export default Hero;
