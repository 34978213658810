import Icon from 'components/Icon';
import Link from 'components/Link';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Parse from 'react-html-parser';

function FloatingActionButton({ fab }) {
    const [hidden, setHidden] = useState(false);
    useEffect(() => {
        const target = fab.hasDisclaimer
            ? document.querySelector('.disclaimer')
            : document.querySelector('footer');
        function callback(entries) {
            entries.forEach((entry) => {
                if (!entry.isIntersecting) {
                    setHidden(false);
                }
                if (entry.isIntersecting || entry.boundingClientRect.top <= 0) {
                    setHidden(true);
                }
            });
        }
        const observer = new IntersectionObserver(callback);
        observer.observe(target);
        return () => {
            observer.disconnect();
        };
    }, []);
    if (!fab || !fab?.items.length > 0) return null;
    return (
        <div
            className={classNames('fab', {
                'fab--hidden': hidden,
                'fab--hasDisclaimer': fab.hasDisclaimer,
                'fab--hasSecondaryNav': fab.hasSecondaryNav,
            })}
            role="dialog"
            aria-modal="true"
        >
            {fab.items.map((item, index) => {
                return (
                    <div className="fab__body" key={index}>
                        <Link href={item.link.cta_link}>
                            <div
                                className={classNames('fab__link', {
                                    'fab__link--orange':
                                        item.theme === 'orange',
                                    'fab__link--cyan': item.theme === 'cyan',
                                    'fab__link--slate': item.theme === 'slate',
                                    'fab__link--royal': item.theme === 'royal',
                                })}
                            >
                                <span className="fab__link-text">
                                    {Parse(item.link.cta_text)}
                                </span>
                                {item.link?.icon && (
                                    <Icon
                                        wrapperIconClassName={classNames(
                                            'fab__icon',
                                            {
                                                'fab__icon--multiple':
                                                    fab.items.length > 1,
                                            }
                                        )}
                                        icon={item.link.icon}
                                    />
                                )}
                            </div>
                        </Link>
                    </div>
                );
            })}
        </div>
    );
}
export default FloatingActionButton;
