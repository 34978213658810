/**
 * References Block
 * A references block is a list of references.
 */

import References1a from './variants/References1a';

const prefix = 'references';

const References = ({ ...block }) => {
	switch (block.variant) {
		case `${prefix}_1a`:
			return <References1a {...block} />;
		default:
			return null;
	}
};

export default References;
