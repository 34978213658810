/**
 * ISI Block
 * A ISI block is a statement for important safety/risk information.
 */

import Disclaimer1a from './variants/Disclaimer1a';

const prefix = 'disclaimer';

const Disclaimer = ({ ...block }) => {
   switch (block.variant) {
      case `${prefix}_1a`:
         return <Disclaimer1a {...block} />;
      default:
         return null;
   }
};

export default Disclaimer;
