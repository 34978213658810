import Footnotes from 'components/Footnotes';
import classNames from 'classnames';

const References1a = ({ theme, description, spacing_top, label, meta }) => {
    return (
        <section
            className={classNames(`section section--bottom-tight bg-${theme}`, {
                [`section--top-${spacing_top}`]: spacing_top,
            })}
            {...meta}
        >
            <div className="container references">
                {label && <h4>{label}</h4>}
                <Footnotes footnotes={description} />
            </div>
        </section>
    );
};

export default References1a;
