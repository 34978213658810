import { getHomePage } from 'lib/queries/getHomePage';
import Layout from 'components/Layout';
import Hero from 'components/Hero';
import ContentZone from 'components/ContentZone';
import References from 'blocks/References';
import Disclaimer from 'blocks/Disclaimer';
import Notification from 'components/Notification';
import FloatingActionButton from 'components/FloatingActionButton';

const HomePage = ({ data }) => {
    return (
        <>
            {data?.notification && <Notification {...data?.notification} />}
            <Layout {...data}>
                <main id="main">
                    {data?.hero && <Hero {...data.hero} />}
                    {data?.floating_action_button && (
                        <FloatingActionButton
                            {...data.floating_action_button}
                        />
                    )}
                    {data?.content_zone && (
                        <ContentZone {...data.content_zone} />
                    )}
                    {data?.references && <References {...data.references} />}
                    {data?.disclaimer && <Disclaimer {...data.disclaimer} />}
                </main>
            </Layout>
        </>
    );
};

export default HomePage;

export const getStaticProps = async (context) => {
    const data = await getHomePage(context);
    if (!data) {
        return {
            notFound: true,
        };
    }
    return {
        props: {
            data,
        },
        revalidate: 60,
    };
};
